import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { LogoOnlyHeader } from "../../organisms/headers/LogoOnlyHeader";
import { Auth, Hub } from "aws-amplify";
import { japaneseList } from "../../../Resources/japaneseList";
import LoadingScreen from "../../molecules/others/LoadingScreen";
import styled from "styled-components";
import * as Sentry from "@sentry/browser";
import { hasSpace, hasFullWidthCharacter } from "../../../Utils/DisplayLogic";

const SignInResource = japaneseList.Authenticator.SignIn;
const styles = (theme) => {
  return {
    item: {
      display: "block",
      marginTop: theme.spacing.unit * 2,
      width: "100%",
    },
    formControlLabel: {
      textAlign: "left",
      fontSize: "12px",
      letterSpacing: "0px",
      color: "#393939",
      opacity: 1,
      marginLeft: "8px",
      marginTop: "1px",
      fontFamily: "inherit",
    },
    formControlRoot: {
      marginLeft: "0px",
    },
    textFieldInput: {
      backgroundColor: theme.color.primaryVariant3,
      border: `1px solid #DDDDDD`,
      borderRadius: "3px",
      padding: "1px",
    },
    textFieldLabel: {
      color: "#808080",
      fontSize: "12px",
      fontWeight: "bold",
      transform: "scale(1)",
    },
    inputPropsStyle: {
      height: "14px",
      WebkitBoxShadow: `0 0 0 30px ${theme.color.primaryVariant3} inset !important`,
      backgroundClip: "content-box",
      padding: "10px",
    },
    checkboxRoot: {
      color: "#CCCCCC",
      width: "18px",
      height: "18px",
      background: "#EEEEEE 0% 0% no-repeat padding-box",
      opacity: 1,
      "&:hover": {
        background: "#EEEEEE 0% 0% no-repeat padding-box",
      },
    },
    checked: {
      color: `${theme.color.primary1} !important`,
    },
    button: {
      display: "block",
      marginTop: "14px",
      width: "100%",
      color: theme.button.ok.color,
      backgroundColor: theme.button.ok.backgroundColor,
      boxShadow: `0px 2px 0px ${theme.button.ok.shadowColor}`,
      "&:hover": {
        backgroundColor: theme.button.ok.backgroundColorHover,
      },
      fontWeight: "bold",
    },
  };
};

const SignInButtonDiv = styled.div`
  padding: 20px auto 20px auto;
  margin: 0 0 16px 0;
  border-radius: 5px;
  opacity: 1;
`;

const PasswordForgetLinkAnchor = styled.span`
  cursor: pointer;
  color: #2563c0;
  font: 12px;
  letter-spacing: 0px;
  opacity: 1;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

const SignInForm = ({ classes, authcb }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);

  const showLoading = () => {
    setIsLoading(true);
  };

  const hideLoading = () => {
    setIsLoading(false);
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handlePasswordChange = (event) => {
    const { value } = event.target;
    setPassword(value);
  };

  const handleTogglePassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  // 画面下にメッセージを出す（MessageBoxのサブスクライブと連動）
  const showMessageBox = (msg) => {
    Hub.dispatch(
      "msg",
      { event: "open", data: { message: msg, level: "error" } },
      "SignIn"
    );
  };

  const handleSignIn = (event) => {
    // フォームで画面遷移しないようにする
    event.preventDefault();

    // Validation
    if (!email || !password || password.length < 6) {
      showMessageBox(SignInResource.signIn.j001);
      return;
    } else if (hasSpace(email) || hasSpace(password)) {
      showMessageBox(SignInResource.signIn.j006);
      return;
    } else if (
      hasFullWidthCharacter(email) ||
      hasFullWidthCharacter(password)
    ) {
      showMessageBox(SignInResource.signIn.j005);
      return;
    }

    // ログイントライ中はローディング画面を出す
    showLoading();

    Auth.signIn({
      username: email,
      password: password,
    })
      .then((user) => {
        Sentry.setUser({
          id: user.username,
        });
        Sentry.captureMessage("signin", Sentry.Severity.Log);

        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          // パスワード変更が必要な場合は、変更画面に遷移する
          authcb("NEW_PASSWORD_REQUIRED", user);
        } else {
          // Because we mark user's email as verified when we add user to cognito pool
          // There won't be a case that user is not verified (email) unless you forgot to check it when you add user on aws console.
          Auth.verifiedContact(user);
        }
      })
      .catch((err) => {
        Sentry.captureException(err.code ? new Error(err.code) : err);

        if (
          // ユーザーが存在しない場合
          err.code === "UserNotFoundException" ||
          // パスワードが一致しない場合
          err.code === "NotAuthorizedException" ||
          // TODO: パターンが不明 引数がなかったとき？
          err.code === "InvalidParameterException"
        ) {
          showMessageBox(SignInResource.signIn.j001);
        } else if (err.code === "UserLambdaValidationException") {
          // アカウントがロックされている場合
          showMessageBox(SignInResource.signIn.j002);
        } else if (
          // パスワードリセット後、パスワード変更が完了していない場合
          err.code === "PasswordResetRequiredException" ||
          // ユーザーのステータスがUNCONFIRMEDの場合
          err.code === "UserNotConfirmedException"
        ) {
          showMessageBox(SignInResource.signIn.j003);
        } else {
          // Cognitoの内部エラーや多すぎるリクエスト、その他のネットワークエラーやタイムアウトなどのエラー全て
          showMessageBox(SignInResource.signIn.j004);
        }
      })
      .finally(() => {
        // 結果にかかわらず最後にローディング画面を消す
        hideLoading();
      });
  };

  return (
    <React.Fragment>
      <LogoOnlyHeader />
      <Grid item>
        <form>
          <TextField
            className={classes.item}
            fullWidth
            autoFocus
            name="email"
            label={SignInResource.render.Grid.Grid.form.TextField.j001}
            onChange={handleEmailChange}
            // Properties applied to the Input element
            InputProps={{
              disableUnderline: true,
              className: classes.textFieldInput,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.textFieldLabel,
              focused: false,
            }}
            // Attributes applied to the native input element
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ className: classes.inputPropsStyle }}
          />
          <TextField
            className={classes.item}
            fullWidth
            name="password"
            type={isShowPassword ? "text" : "password"}
            key="password"
            label={SignInResource.render.Grid.Grid.form.TextField.j002}
            onChange={handlePasswordChange}
            // Properties applied to the Input element
            InputProps={{
              disableUnderline: true,
              className: classes.textFieldInput,
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.textFieldLabel,
              focused: false,
            }}
            // Attributes applied to the native input element
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{ className: classes.inputPropsStyle }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="isShowPassword"
                classes={{
                  root: classes.checkboxRoot,
                  checked: classes.checked,
                }}
              />
            }
            classes={{
              root: classes.formControlRoot,
              label: classes.formControlLabel,
            }}
            onChange={handleTogglePassword}
            label={SignInResource.render.Grid.Grid.form.CheckBox.j001}
          />
          <SignInButtonDiv>
            <Button
              className={classes.button}
              variant="raised"
              size="large"
              type="submit"
              fullWidth
              onClick={handleSignIn}
              id="sign-in-button"
            >
              {SignInResource.render.Grid.Grid.form.Button.j001}
            </Button>
          </SignInButtonDiv>
          <div>
            <Typography variant="caption" align="center">
              <PasswordForgetLinkAnchor
                onClick={() => authcb("FORGOT_PASSWORD")}
              >
                {SignInResource.render.Grid.Grid.form.Button.Typography.j002}
              </PasswordForgetLinkAnchor>
            </Typography>
          </div>
          <LoadingScreen isLoading={isLoading} />
        </form>
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(styles)(SignInForm);
